<template>
  <div>

    <div alt="cover photo" class="card profile-header mb-2">
      <img src="../../public/anchor-banner.png" class="card-img-top">
    </div>

    <b-overlay variant="white" :show="showLoading" spinner-variant="primary" opacity=".75" rounded="sm">
      <b-row>
        <b-col cols="12">
        <b-card>
          <h4 class="card-title mb-1">Order List <span class="refresh-btn" @click="getData()"><feather-icon icon="RefreshCwIcon" size="14"/></span></h4>
          <b-row>
            <b-col cols="12" class="mb-1">
              <b-input-group size="sm">
                <b-form-input
                        @change="chageFilter"
                        v-model="filter"
                        type="search"
                        placeholder="Search"/>
                <b-input-group-append>
                  <b-button @click="getData()" variant="primary">Search</b-button>
                </b-input-group-append>
              </b-input-group>
            </b-col>

            <b-col cols="12" class="pb-1">

              <table class="table order-list-table mw-100 table-responsive-lg">
                <tr class=" table-row1">
                  <th @click="sortClick('loadingDate')">LOADING DATE
                    <span class="up"><feather-icon icon="ChevronUpIcon" size="14"/></span>
                    <span class="down"><feather-icon icon="ChevronDownIcon" size="14"/></span>
                  </th>
                  <th @click="sortClick('etd')">ETD
                    <span class="up"><feather-icon icon="ChevronUpIcon" size="14"/></span>
                    <span class="down"><feather-icon icon="ChevronDownIcon" size="14"/></span>
                  </th>
                  <th>ETA
                    <!--<span class="up"><feather-icon icon="ChevronUpIcon" size="14"/></span>
                    <span class="down"><feather-icon icon="ChevronDownIcon" size="14"/></span>-->
                  </th>
                  <th @click="sortClick('orderNo')">SO NO.
                    <span class="up"><feather-icon icon="ChevronUpIcon" size="14"/></span>
                    <span class="down"><feather-icon icon="ChevronDownIcon" size="14"/></span>
                  </th>
                  <th>ANCHOR JOB NO.
                    <!--<span class="up"><feather-icon icon="ChevronUpIcon" size="14"/></span>
                    <span class="down"><feather-icon icon="ChevronDownIcon" size="14"/></span>-->
                  </th>
                  <th>POL
                    <!--<span class="up"><feather-icon icon="ChevronUpIcon" size="14"/></span>
                    <span class="down"><feather-icon icon="ChevronDownIcon" size="14"/></span>-->
                  </th>
                  <th @click="sortClick('endPortName')">POD
                    <span class="up"><feather-icon icon="ChevronUpIcon" size="14"/></span>
                    <span class="down"><feather-icon icon="ChevronDownIcon" size="14"/></span>
                  </th>
                  <th @click="sortClick('shortName')">COMPANY
                    <span class="up"><feather-icon icon="ChevronUpIcon" size="14"/></span>
                    <span class="down"><feather-icon icon="ChevronDownIcon" size="14"/></span>
                  </th>

                </tr>

                <tr @click="onRowSelected(cell,index)" v-for="(cell , index) in items" :key="index" class="table-row" :class="active === index ? 'isActive' : ''" >
                  <td>{{cell.loadingDate2}}</td>
                  <td>{{cell.etd3}}</td>
                  <td>{{cell.eta2}}</td>
                  <td>
                    {{cell.orderNo}}
                    <span class="position-log" @click.stop="containerModal(cell)" v-if="cell.locationCount > 0">{{cell.locationCount}}</span>
                  </td>
                  <td>{{cell.containerCode}}</td>
                  <td>{{cell.startPortName}}</td>
                  <td>{{cell.endPortName}}</td>
                  <td>{{cell.shortName}}</td>
                </tr>
              </table>

            </b-col>

            <b-col md="6" cols="12">
              <b-form-group class="mb-0">
                <label class="d-inline-block text-sm-left mr-50">Per page</label>
                <b-form-select id="perPageSelect" @change="changePer" v-model="perPage" size="sm" :options="pageOptions" style="width: 80px"/>
                <span class="d-inline-block text-sm-left">&nbsp; Total: {{totalRows}}</span>
              </b-form-group>
            </b-col>
            <b-col md="6" cols="12">
              <b-pagination
                      @change="changePage"
                      v-model="currentPage"
                      :total-rows="totalRows"
                      :per-page="perPage"
                      aria-controls="selectableTable"
                      size="sm"
                      class="my-0 mt-md-0 mt-1 justify-content-md-end justify-content-center"/>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      </b-row>
    </b-overlay>
    <b-row>
      <b-col cols="12">
        <b-card>
          <h4 class="card-title mb-1" id="order-detail">Order Detail</h4>
          <b-tabs>
            <b-tab title="Cargo info">
              <div v-if="item !== '' " class="list" >
                <!-- 整柜 -->
                <div>
                  <table >
                    <tr >
                      <td><span>SERVICE CODE :</span></td>
                      <td><span>{{item.containerCode}}</span></td>
                    </tr>
                    <tr >
                      <td><span>SO NO. :</span></td>
                      <td><span>{{item.orderNo}}</span></td>
                    </tr>
                    <tr >
                      <td><span>CARRIER :</span></td>
                      <td><span>{{item.carrierName}}</span></td>
                    </tr>
                    <tr >
                      <td><span>B/L NO. :</span></td>
                      <td><span>{{item.mlb}}</span></td>
                    </tr>
                    <tr >
                      <td><span>VESSEL/VOYAGE :</span></td>
                      <td><span>{{item.caseNo}}/{{item.flightNo}}</span></td>
                    </tr>
                    <tr >
                      <td><span>LOADING DATE :</span></td>
                      <td><span>{{item.loadingDate4}}</span></td>
                    </tr>
                    <tr >
                      <td><span>SI CUT OFF :</span></td>
                      <td><span>{{item.cutOffDate}}</span></td>
                    </tr>
                    <tr >
                      <td><span>CY CUT OFF :</span></td>
                      <td><span>{{item.portCutOffDate}}</span></td>
                    </tr>
                    <tr >
                      <td><span>VGM CUT OFF :</span></td>
                      <td><span>{{item.vgmCutOffDate}}</span></td>
                    </tr>
                    <tr >
                      <td><span>ETD :</span></td>
                      <td><span>{{item.etd4}}</span></td>
                    </tr>
                    <tr >
                      <td><span>ETA :</span></td>
                      <td><span>{{item.eta2}}</span></td>
                    </tr>
                    <tr >
                      <td><span>POL :</span></td>
                      <td><span>{{item.startPortName}}</span></td>
                    </tr>
                    <tr >
                      <td><span>PORT OF TRANSHIPMENT :</span></td>
                      <td><span>{{item.transitPortName}}</span></td>
                    </tr>
                    <tr >
                      <td><span>POD :</span></td>
                      <td><span>{{item.endPortName}}</span></td>
                    </tr>
                    <tr >
                      <td><span>VOLUME :</span></td>
                      <td><span>{{item.box}}</span></td>
                    </tr>
                    <tr >
                      <td><span>CTN NO./SEAL NO. :</span></td>
                      <td><span>{{item.boxNo}}/{{item.sealNo}}</span></td>
                    </tr>
                    <tr>
                      <td><span>free time&det/dem :</span></td>
                      <td><span style="display:inline-block;padding-top:12px">{{item.freeTime}} <span > DAYS</span> </span></td>
                    </tr>
                  </table>
                </div>
                <!-- 整柜 end -->

              </div>
              <p v-if="item === '' " class="text-center">No data</p>
            </b-tab>

            <b-tab title="Tracking">

              <div v-if="tracking !== '' " class="tracking">
                <app-timeline >
                  <app-timeline-item :variant="item.type" :key="index" v-for="(item,index) in tracking">
                    <h6>{{item.progressSpeedName}}</h6>
                    <p class="mb-0">Plan Time：{{item.planDate2}}<span v-if="item.planRemark != null" >,({{item.planRemark}})</span></p>
                    <p class="mb-0">Actual Time：{{item.arrivalDate4}}<span v-if="item.progressRemark != null">,({{item.progressRemark}})</span></p>
                  </app-timeline-item>
                </app-timeline>

              </div>
              <p v-if="tracking === '' " class="text-center">No data</p>

            </b-tab>
          </b-tabs>
        </b-card>
      </b-col>
    </b-row>

    <b-modal ref="mapModal" id="mapModal" size="xl" title="Select the cabinet number, display the logistics" hide-footer>
      <b-row class="mb-1">
        <b-col lg="5" cols="12">
          <b-form-select @change="containerMap(selected)" v-model="selected" :options="containerOption"></b-form-select>
        </b-col>
      </b-row>

      <div>
        <div id="container" class="map"></div>
      </div>

      <div class="bottom-driver mt-1" v-if="driverInfo" style="color: #000" >
        <div><span>SO号 ：</span><span>{{driverInfo.order_no}}</span></div>
        <div><span>柜 号 ：</span><span>{{driverInfo.container_code}}</span></div>
        <div><span>车 牌 ：</span><span>{{driverInfo.car_no}}</span></div>
        <div><span>司 机 ：</span><span>{{driverInfo.driver_name}}</span></div>
        <div><span>手 机 ：</span><span>{{driverInfo.driver_phone}}</span></div>
      </div>

    </b-modal>

  </div>
</template>

<script>

import {BOverlay,BTable , BRow, BCol, BFormGroup,BTabs, BTab, BCard, BLink , BInputGroup, BFormInput, BInputGroupAppend, BButton,BPagination,BFormSelect} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import $ from 'jquery';

export default {
  components: {
    BCard, BLink,BOverlay,
    BTable, BRow, BCol, BFormGroup,BTabs, BTab, BInputGroup, BFormInput, BInputGroupAppend, BButton,BPagination,BFormSelect,
    AppTimeline,
    AppTimelineItem,

  },
  props: {
    latLng: Array,
  },
  data() {
    return {
      showLoading:false,
      formData:{
        searchStr:'',
        sortBy:'etd',
        sortName:'desc',
        pageNum:'1',
        pageSize:'10',
        contactId:localStorage.getItem('contactId')
      },
      isBusy:false,
      perPage: 10,
      pageOptions: [5,10,20,50],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      items: [],
      item:'',
      tracking:'',
      active:-1,
      selected: null,
      containerOption: [{value:'',text:''}],
      driverInfo:null,

      center: { lng: 0, lat: 0 },
      zoom: 11,
      map: {},
      mapObj: [],//结构[{ lng: '', lat: '',title:''}]

      paths: [],// 线路节点
      markerArr:[],
    }
  },
  computed: {},
  mounted() {
    if (localStorage.getItem('username') !== null){
      this.getData();
    }else {
      this.$router.push({path: '/login'});
    }
  },
  methods: {
    changePer(){
      this.formData.pageSize = this.perPage;
      this.getData()
    },
    sortClick(ctx) {
      this.formData.sortBy = ctx;
      this.formData.searchStr = this.filter;
      this.formData.pageSize = this.perPage;
      this.formData.pageNum = this.currentPage;
      if (this.formData.sortName === 'desc') {
        this.formData.sortName = 'asc';
      }else {
        this.formData.sortName = 'desc';
      }
      this.getData()
    },
    changePage(page){
      this.formData.pageNum = page;
      this.getData()
    },
    chageFilter() {
      this.formData.pageNum = 1;
      this.currentPage = 1;
      this.formData.searchStr = this.filter;
      this.getData()
    },
    getData(){
      this.showLoading =true;
      this.$http({
          method: 'post',
          url: this.GLOBAL.rootPath + '/customer/order/findByContactId',
          data: $.param(this.formData),
        }).then(res => {
          this.showLoading = false;
          if (res.data.result) {
            this.items = res.data.voOrder.items;
            this.totalRows = res.data.voOrder.totalCount;
          } else {
            this.items =[];
            this.totalRows =0;
          }
        }).catch(error => {
          this.showLoading = false;
          console.log(error);
        });
    },
    onRowSelected(items,index) {
      this.active = index;
      this.showLoading = true;
      this.$http({
        method: 'post',
        url: this.GLOBAL.rootPath + '/customer/order/findLogisticByOrderId',
        data: $.param({orderId:items.orderId}),
      }).then(res => {
        this.showLoading = false;
        if (res.data.result) {

          var scroll_offset = $('#order-detail').offset();
          $("body,html").animate({scrollTop: scroll_offset.top}, 0);
          //document.querySelector('#' + 'order-detail').scrollIntoView();
          this.item = res.data.voOrder;
          this.tracking = res.data.voLogistic;
          for (let i=0; i< res.data.voLogistic.length; i++ ){
            if (res.data.voLogistic[i].arrivalDate4 !== null && res.data.voLogistic[i].arrivalDate4 !== undefined && res.data.voLogistic[i].arrivalDate4 !== '' ){
              this.tracking[i].type = 'success';
            } else {
              this.tracking[i].type = 'secondary';
            }
          }
        } else {
            this.item = '';
            this.tracking = ''
        }
      }).catch(error => {
        this.showLoading = false;
        console.log(error);
      });
    },
    containerModal(item){
      this.showLoading = true;
      this.$http({
        method: 'get',
        url: this.GLOBAL.rootPath + '/customer/order/getConatainerList?orderId='+item.orderId,
      }).then(res => {
        this.showLoading = false;
        if (res.data.result) {
          for (let i =0 ; i< res.data.data.length;i++){
            this.containerOption[i].value = res.data.data[i].detail_id;
            this.containerOption[i].text = "柜号:"+res.data.data[i].container_code+",拖车定位中";
          }
          this.driverInfo = res.data.data[0];
          this.selected = this.containerOption[0].value;
          this.$refs['mapModal'].show();
          this.containerMap(this.selected);
        } else {}
      }).catch(error => {
        this.showLoading = false;
        console.log(error);
      });
    },
    containerMap(orderDetailId){
      this.$http({
        method: 'get',
        url: this.GLOBAL.rootPath + '/customer/order/getOneContainerLocation?orderDetailId='+orderDetailId,
      }).then(res => {
        if (res.data.result) {
          this.mapObj = res.data.data;
          for (let i = 0;i< res.data.data.length;i++) {
            this.mapObj[i].lng = res.data.data[i].longitude;
            this.mapObj[i].lat = res.data.data[i].latitude;
            this.mapObj[i].title = res.data.data[i].gpsTime;
            this.mapObj[i].content = res.data.data[i].gpsTime;
            this.mapObj[i].position = new TMap.LatLng(res.data.data[i].latitude,res.data.data[i].longitude );
            this.mapObj[i].styleId = 'label';
          }
          this.markerArr = this.mapObj;
          this.center = { lng: this.mapObj[0].longitude, lat: this.mapObj[0].latitude};
          this.init();
        } else {}
      }).catch(error => {
        console.log(error);
      });
    },

    init() {
      var myOptions = {
        zoom: 13,
        center: new TMap.LatLng(this.center.lat,this.center.lng),
        marker:this.mapObj,
        scrollwheel: true,
        scaleControl: false,//启用比例尺
        zoomControl: false,
        panControl: true,// 平移控件
        mapTypeControl: false,// 默认是地图和卫星
      };
      var map = new TMap.Map(document.getElementById("container"), myOptions);

      if(this.mapObj.length > 0){
        let path = [];
        this.mapObj.forEach(item => {
          path.push(new TMap.LatLng(item.lat,item.lng));
        });
        var polyline = new TMap.MultiPolyline({
          map: map,
          styles: {
            'polyline': new TMap.PolylineStyle({
              'color': '#0091ea', //线填充色
              'width': 8, //折线宽度
              'borderWidth': 8, //边线宽度
              'borderColor': 'rgba(0,125,255,0.5)', //边线颜色
              'lineCap': 'round' //线端头方式
            }),
          },
          geometries: [{paths:path}],
        });

        var marker = new TMap.MultiMarker({
          map: map,
          styles: {
            // 点标记样式
            marker: new TMap.MarkerStyle({
              width: 15,
              height: 15,
              anchor: { x: 5, y: 15 }, // 描点位置
              color: '#3777FF', // 颜色属性
              size: 16, // 文字大小属性
              offset: { x: 0, y: 0 },
            }),
            label: new TMap.MarkerStyle({
              color: '#FF1E0D', // 颜色属性
              size: 16, // 文字大小属性
              offset: { x: 0, y: 0 }, // 文字偏移属性单位为像素
              direction: 'bottom',
              strokeColor: '#fff', // 标注点文本描边颜色
              strokeWidth: 7, // 标注点文本描边宽度
              src:'https://anchor.embraiz.com//truckM11.png'
            }),

          },
          geometries: this.markerArr,
        });

      }else {}
    },
  },



}
</script>

<style>
  .list table td{padding:4px 10px;color: #000;font-size: 16px}
  .tracking h6{color: #000000}
  table.my-table td,
  table.my-table th{
    padding: 10px 5px;
  }
  .refresh-btn{cursor: pointer;color: #7367f0;font-weight: bold}
  .order-list-table{
    overflow-x: auto;
  }
  .order-list-table .table-row.isActive,
  .order-list-table .table-row:focus,
  .order-list-table .table-row:hover{background-color: #c7bfe5;}
  .order-list-table .table-row{cursor: pointer;}
  .order-list-table .table-row td:first-child,
  .order-list-table .table-row td:nth-child(2),
  .order-list-table .table-row td:nth-child(2),
  .order-list-table .table-row td:nth-child(7){
    white-space: nowrap;
  }
  .order-list-table .table-row td{
    border-bottom: 1px solid rgba(204, 204, 204, 0.51);
    overflow: hidden;
    max-width: 200px;
    text-overflow-ellipsis: ellipsis;
    padding:10px 12px;
    position: relative;
    color: #000000;
  }
  .order-list-table .table-row1{
    background-color: #c7bfe5;
    padding: 3px 0;
    color: #000;
    font-size: 12px;
  }
  .order-list-table .table-row1 th{cursor: pointer;position: relative;padding:12px}
  .order-list-table .table-row1{
    border-bottom: 1px solid rgba(204, 204, 204, 0.51);
  }
  .order-list-table .table-row1 th span.up{
    top: 8px;
  }
  .order-list-table .table-row1 th span.down{bottom: 8px}
  .order-list-table .table-row1 th:hover{
    color: #000000;
  }
  .order-list-table .table-row1 th span{
    position: absolute;
    right: 10px;
    cursor: pointer;
  }
  .position-log{
    position: absolute;
    top:7px;
    right: 0px;
    background-color: #7367f0;
    border-radius: 100%;
    padding: 1px 1px;
    width: 20px;
    height: 20px;
    display: inline-block;
    text-align: center;
    color: white;
    z-index: 99;
    font-size: 12px;
  }
  .position-log:hover{
    background-color: #28c76f;
  }
  .map {
    width: 100%;
    height: 60vh;
  }
  .BMap_Marker .BMapLabel{padding: 1px 4px}
  div,label,span{color: #000000}
  img.card-img-top{object-fit: contain}
  .nav-tabs .nav-link {
    color: #000000;
  }
  h4{color: #000000}
  @media screen and (max-width: 1199px){
    .order-list-table .table-row1 th span {
      right: 0;
    }
  }
  @media screen and (max-width: 767px){
    body div.app-content {
      padding: 10px !important;
    }
    .order-list-table .table-row,
    .order-list-table .table-row1{

    }
    .order-list-table .table-row td{
      max-width: 80px;
      overflow: hidden;
      padding: 8px 4px;
      white-space: nowrap;
    }
    .order-list-table .table-row td,
    .order-list-table .table-row1 th {
      font-size: 12px;
    }
    .card-body {
      padding: 1rem;
    }
    .nav-tabs .nav-link {
      padding: 0.61rem 0.8rem;
    }
    .order-list-table .table-row1 th span.up{

    }
    .order-list-table .table-row1 th span.down{


    }
    .list span{font-size: 12px}
    .list .row .col:nth-child(1){
      padding-right: 0;
    }
    .position-log {
      background-color: rgba(115, 103, 240, 0.8);
      top: 0;
    }
    .position-log:hover {
      background-color: rgba(40, 199, 111, 0.8);
    }
    img.card-img-top{}
  }

</style>

